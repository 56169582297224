import _ from 'lodash';

import { Logger, UserInput } from '../../generic/utils';
import { wrapperBuilder } from './build';
import { wrapperIdentifier } from './common';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

function buildCoreAllianzError(reference: string, error: any) {
  if (error?.referenceCode) {
    return error;
  }

  const errorArray = error?.message?.toString().split('|');
  const referenceCode = `CORE-${reference}`;
  const errorMessage = `${referenceCode}|${errorArray[0]}`;
  return {
    statusCode: 400,
    name: 'BadRequestError',
    message: errorMessage,
    safeMessage: error?.message?.toString(),
    referenceCode,
    serviceName: 'webapp-core',
    safeStack: [],
  };
}

export type InstanciateNewtarget = (targetEspecification?: {
  targetType?: string;
  productIdentifier?: string;
  parentTarget?: string;
  agentPA?: string;
}) => Promise<void>;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'instanciateNewTarget',
    ({
      bindedContexts: { user, getInstanceApi, loadTargets, saveTargetProperty },
    }) => async targetEspecification => {
      try {
        if (!user) {
          throw new Error('No user defined');
        }

        // Get Allianz Product
        const productDefinitions = await getInstanceApi().getAllianzSupportedProducts();
        if (productDefinitions.level === 'error') {
          throw buildCoreAllianzError('NT', productDefinitions.payload);
        }

        const allianzProduct = productDefinitions.find(
          (product: { identifier: string | undefined }) =>
            product.identifier === targetEspecification?.productIdentifier
        );
        if (!allianzProduct) {
          throw new Error('Allianz product not found');
        }

        // Create target
        const createdTarget = await getInstanceApi().createTargetForUser(
          user.id,
          {
            parentTargetId: targetEspecification?.parentTarget,
            targetTypeId: targetEspecification?.targetType,
          }
        );
        if (createdTarget.level === 'error') {
          throw buildCoreAllianzError('NT', createdTarget.payload);
        }
        if (!createdTarget) {
          throw new Error('Target not created');
        }

        // console.log('## createdTarget', createdTarget);

        // Create policy
        const createdPolicy = await getInstanceApi().createAllianzPolicy(
          createdTarget.id,
          allianzProduct.id
        );
        if (createdPolicy.level === 'error') {
          throw buildCoreAllianzError('NT', createdPolicy.payload);
        }
        if (!createdPolicy) {
          throw new Error('Allianz policy not created');
        }

        // Update target
        const creationData = {
          policy: createdPolicy,
          agentPA: targetEspecification?.agentPA
        };
        await saveTargetProperty(
          createdTarget.id,
          'creationData',
          creationData
        );

        await loadTargets();

        return {
          ...createdTarget,
          targetProperties: [
            {
              name: 'creationData',
              value: creationData,
              targetId: createdTarget.id,
            },
          ],
        };
      } catch (e) {
        console.log('## instanciateNewTarget CORE CATCH ERROR', e);
        const error = e as any;
        if (error?.referenceCode) {
          throw e;
        }
        throw buildCoreAllianzError('NT', e);
      }
    }
  );

export type GetTargetPolicy = (targetId: string) => Promise<void>;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'getTargetPolicy',
    ({ bindedContexts: { getInstanceApi } }) => async targetId => {
      if (!targetId) {
        throw new Error('No targetId defined');
      }

      // Get Allianz Product
      try {
        const policy = await getInstanceApi().getAllianzPolicy(targetId);
        if (!policy) {
          throw new Error('Allianz policy not found');
        }
        if (policy.level === 'error') {
          throw buildCoreAllianzError('GTP', policy.payload);
        }
        return policy;
      } catch (e) {
        console.log('## getTargetPolicy CORE CATCH ERROR', e);
        const error = e as any;
        if (error?.referenceCode) {
          throw e;
        }
        throw buildCoreAllianzError('GTP', e);
      }
    }
  );

export type SaveTargetProperty = (
  idTarget: string,
  propName: string,
  data: UserInput
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'saveTargetProperty',
    ({ bindedContexts: { targets, saveTargetProperty } }) => async (
      idTarget,
      propName,
      data
    ) => {
      try {
        const target = (targets || []).find(tp => tp.id === idTarget);
        const propData = (target?.targetProperties || []).find(
          tp => tp.name === propName
        );
        const newPropData = {
          ...propData?.value,
          ...data,
        };
        idTarget &&
          !_.isEqual(newPropData, propData) &&
          (await saveTargetProperty(idTarget, propName, newPropData));
      } catch (e) {
        console.log('## saveTargetProperty CORE CATCH ERROR', e);
        throw buildCoreAllianzError('STP', e);
      }
    }
  );

export type GetTowns = (targetId: string, postalCode: string) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'getTowns',
    ({ bindedContexts: { getInstanceApi } }) => async (
      targetId: string,
      postalCode: string
    ) => {
      // Get Allianz Profession Questions
      try {
        const towns = await getInstanceApi().getTowns(targetId, postalCode);
        if (!towns) {
          throw new Error('Allianz postal code - towns error');
        }
        if (towns.level === 'error') {
          throw buildCoreAllianzError('TW', towns.payload);
        }
        return towns;
      } catch (e) {
        console.log('## getTowns CORE CATCH ERROR', e);
        const error = e as any;
        if (error?.referenceCode) {
          throw e;
        }
        throw buildCoreAllianzError('TW', e);
      }
    }
  );

export type GetProfessionQuestions = (
  idTarget: string,
  professionCode: string
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'getProfessionQuestions',
    ({ bindedContexts: { getInstanceApi, loadTargets } }) => async (
      idTarget: string,
      professionCode: string
    ) => {
      // Get Allianz Profession Questions
      try {
        const professionQuestions = await getInstanceApi().getProfessionQuestions(
          idTarget,
          professionCode
        );
        await loadTargets();
        if (!professionQuestions) {
          throw new Error('Allianz profession questions error');
        }
        if (professionQuestions.level === 'error') {
          throw buildCoreAllianzError('PQ', professionQuestions.payload);
        }
        return professionQuestions;
      } catch (e) {
        console.log('## professionQuestions CORE CATCH ERROR', e);
        const error = e as any;
        if (error?.referenceCode) {
          throw e;
        }
        throw buildCoreAllianzError('PQ', e);
      }
    }
  );

export type Tarificate = (idTarget: string, deathCapitalValue: number) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'tarificate',
    ({ bindedContexts: { getInstanceApi, loadTargets } }) => async (
      idTarget: string,
      deathCapitalValue: number
    ) => {
      // Get Allianz Tarification
      try {
        const tarification = await getInstanceApi().tarificate(
          idTarget,
          deathCapitalValue
        );
        await loadTargets();
        if (!tarification) {
          throw new Error('Allianz tarification error');
        }
        if (tarification.level === 'error') {
          throw buildCoreAllianzError('TR', tarification.payload);
        }
        return tarification;
      } catch (e) {
        console.log('## tarification CORE CATCH ERROR', e);
        const error = e as any;
        if (error?.referenceCode) {
          throw e;
        }
        throw buildCoreAllianzError('TR', e);
      }
    }
  );

export type Preemite = (idTarget: string) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'preemite',
    ({ bindedContexts: { getInstanceApi, loadTargets } }) => async (
      idTarget: string
    ) => {
      // Get Allianz Preemission
      try {
        const preemission = await getInstanceApi().preemite(idTarget);
        await loadTargets();
        if (!preemission) {
          throw new Error('Allianz preemission error');
        }
        if (preemission.level === 'error') {
          throw buildCoreAllianzError('PR', preemission.payload);
        }
        return preemission;
      } catch (e) {
        console.log('## preemission CORE CATCH ERROR', e);
        const error = e as any;
        if (error?.referenceCode) {
          throw e;
        }
        throw buildCoreAllianzError('PR', e);
      }
    }
  );

export type PostTargetCustom = (
  idTarget: string,
  methodName: string,
  payload?: any
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'postTargetCustom',
    ({ bindedContexts: { getInstanceApi, loadTargets } }) => async (
      idTarget,
      methodName,
      payload
    ) => {
      await getInstanceApi().postTargetCustom(idTarget, methodName, payload);
      await loadTargets();
    }
  );
