/* eslint-disable no-undef */
export const init = () => {
  cordova.define('cordova-plugin-file.Metadata', function(
    require,
    exports,
    module
  ) {
    /*
     *
     * Licensed to the Apache Software Foundation (ASF) under one
     * or more contributor license agreements.  See the NOTICE file
     * distributed with this work for additional information
     * regarding copyright ownership.  The ASF licenses this file
     * to you under the Apache License, Version 2.0 (the
     * "License"); you may not use this file except in compliance
     * with the License.  You may obtain a copy of the License at
     *
     *   http://www.apache.org/licenses/LICENSE-2.0
     *
     * Unless required by applicable law or agreed to in writing,
     * software distributed under the License is distributed on an
     * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
     * KIND, either express or implied.  See the License for the
     * specific language governing permissions and limitations
     * under the License.
     *
     */

    /**
     * Information about the state of the file or directory
     *
     * {Date} modificationTime (readonly)
     */
    const Metadata = function(metadata) {
      if (typeof metadata === 'object') {
        this.modificationTime = new Date(metadata.modificationTime);
        this.size = metadata.size || 0;
      } else if (typeof metadata === 'undefined') {
        this.modificationTime = null;
        this.size = 0;
      } else {
        /* Backwards compatiblity with platforms that only return a timestamp */
        this.modificationTime = new Date(metadata);
      }
    };

    module.exports = Metadata;
  });
};
