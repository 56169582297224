import moment from 'moment';

import { Logger } from '../../../generic/utils';
import { wrapperIdentifier } from '../common';
import { TRANSPORTS } from './transportDefinitions';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

var inited = false;

export const GoogleTagManager = {
  initialize: async function (
    getInstanceApi: any,
    user: any,
    loadSessionUser: any,
    { debugEnabled }: { debugEnabled?: boolean }
  ) {
    if (!user) {
      inited = false;
      return
    }
    if (!inited) {
      debugEnabled &&
        console.log(`## 📣 ${TRANSPORTS.GOOGLE_TAG_MANAGER} INITING`);
      inited = true;

      let analyticId = user?.metadata?.analytics?.GOOGLE_TAG_MANAGER?.id;

      // SI NO TIENE LA ANALYTIC CREADA SE CREA
      if (!analyticId) {
        try {
          analyticId = await this.startAnalytic({
            getInstanceApi,
            user,
            debugEnabled,
          });
          if (analyticId) {
            await loadSessionUser()
          }
        } catch (error) {
          throw error;
        }
      }
      console.log(
        `## ✅ ${TRANSPORTS.GOOGLE_TAG_MANAGER} analyticId`,
        analyticId
      );

      // SE AÑADEN LOS SCRIPTS
      try {
        const addedScripts = await this.addScripts({
          getInstanceApi,
          debugEnabled,
          analyticId,
        });
        if (addedScripts) {
          debugEnabled &&
            console.log(
              `## ✅ ${TRANSPORTS.GOOGLE_TAG_MANAGER} addedScripts`,
              addedScripts
            );
        }
      } catch (error) {
        throw error;
      }
    }
  },
  startAnalytic: async function (args: { [k: string]: any }) {
    const { getInstanceApi, user, debugEnabled } = args;

    debugEnabled &&
      console.log(
        `## 📣 CREATE NEW ${TRANSPORTS.GOOGLE_TAG_MANAGER} ANALYTICS`
      );

    try {
      const apiInstance = getInstanceApi();

      const transports = await apiInstance.microserviceCall('Analytics')(
        '/transports',
        'GET',
        {
          filter: { where: { identifier: TRANSPORTS.GOOGLE_TAG_MANAGER } },
        }
      );

      if (transports.length === 0) {
        throw new Error('GOOGLE_TAG_MANAGER is not actived');
      }

      const analyticsCreated = await apiInstance.microserviceCall('Analytics')(
        `/users/${user.id}/anltcs`,
        'POST',
        {
          transportId: transports[0].id,
          input: {},
        }
      );

      debugEnabled &&
        console.log(
          ` ${TRANSPORTS.GOOGLE_TAG_MANAGER} analyticsCreated`,
          analyticsCreated
        );

      let analyticPooled: any = {};
      let status = analyticsCreated.status;
      let finishDate = moment()
        .add(20, 'seconds')
        .toDate();
      while (
        (status !== 'CREATED_SUCCESS' && status !== 'CREATED_ERROR') ||
        +new Date() > +finishDate
      ) {
        await new Promise(r => setTimeout(r, 1000));
        analyticPooled = await apiInstance.microserviceCall('Analytics')(
          `/anltcs/${analyticsCreated.id}`,
          'GET'
        );
        status = analyticPooled.status;
      }

      if (status === 'CREATED_ERROR') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.GOOGLE_TAG_MANAGER} startAnalytic analyticPooled`,
            analyticPooled
          );
        throw new Error('Error creating analytic');
      } else if (status !== 'CREATED_SUCCESS') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.GOOGLE_TAG_MANAGER} startAnalytic analyticPooled`,
            analyticPooled
          );
        throw new Error('Timeout reached creating analytic');
      }
      // debugEnabled &&
      //   console.log(
      //     `## ✅ ${TRANSPORTS.GOOGLE_TAG_MANAGER} startAnalytic analyticPooled`,
      //     analyticPooled
      //   );

      return analyticsCreated.id;
    } catch (error) {
      debugEnabled && console.log('## ❌ error', error);
      throw error;
    }
  },
  addScripts: async function (args: { [k: string]: any }) {
    const { debugEnabled } = args;

    try {
      const scripts = await this.getScripts(args);
      debugEnabled &&
        console.log(`## 🧩 ${TRANSPORTS.GOOGLE_TAG_MANAGER} scripts`, scripts);

      const script = this.createScript(scripts.script);
      const iframeSrc = this.createIframe(scripts.iframeSrc);
      const noscript = this.createNoScript(iframeSrc);

      document.head.prepend(script);
      document.body.prepend(noscript);

      return true;
    } catch (error) {
      debugEnabled && console.log('## ❌ error', error);
      throw error;
    }
  },
  getScripts: async function (args: { [k: string]: any }) {
    const { getInstanceApi, debugEnabled, analyticId } = args;
    debugEnabled &&
      console.log(`## 🧩 ${TRANSPORTS.GOOGLE_TAG_MANAGER} GET SCRIPTS`);

    try {
      const apiInstance = getInstanceApi();

      const eventDefinitions = await apiInstance.microserviceCall('Analytics')(
        '/event-definitions',
        'GET',
        {
          filter: {
            where: {
              identifier: `${TRANSPORTS.GOOGLE_TAG_MANAGER}_GENERATE_SCRIPTS`,
              transportIdentifier: TRANSPORTS.GOOGLE_TAG_MANAGER,
            },
          },
        }
      );

      if (eventDefinitions.length === 0) {
        throw new Error(
          'GENERATE_SCRIPTS for GOOGLE_TAG_MANAGER is not actived'
        );
      }

      const eventCreated = await apiInstance.microserviceCall('Analytics')(
        `/anltcs/${analyticId}/events`,
        'POST',
        {
          eventDefinitionId: eventDefinitions[0].id,
          input: {},
        }
      );
      debugEnabled &&
        console.log(
          `## ${TRANSPORTS.GOOGLE_TAG_MANAGER} eventCreated`,
          eventCreated
        );

      let eventPooled: any = {};
      let status = eventCreated.status;
      let finishDate = moment()
        .add(20, 'seconds')
        .toDate();
      while (
        (status !== 'FINISHED_SUCCESS' && status !== 'FINISHED_ERROR') ||
        +new Date() > +finishDate
      ) {
        await new Promise(r => setTimeout(r, 1000));
        eventPooled = await apiInstance.microserviceCall('Analytics')(
          `/events/${eventCreated.id}`,
          'GET'
        );
        debugEnabled &&
          console.log(
            `## ${TRANSPORTS.GOOGLE_TAG_MANAGER} eventPooled`,
            eventPooled
          );
        status = eventPooled.status;
      }

      if (status === 'FINISHED_ERROR') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.GOOGLE_TAG_MANAGER} getScripts eventPooled`,
            eventPooled
          );
        throw new Error('Error creating event');
      } else if (status !== 'FINISHED_SUCCESS') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.GOOGLE_TAG_MANAGER} getScripts eventPooled`,
            eventPooled
          );
        throw new Error('Timeout reached creating event');
      }
      // debugEnabled &&
      //   console.log(
      //     `## ✅ ${TRANSPORTS.GOOGLE_TAG_MANAGER} getScripts eventPooled`,
      //     eventPooled
      //   );

      return { ...eventPooled.output };
    } catch (error) {
      debugEnabled && console.log('## ❌ error', error);
      throw error;
    }
  },
  createScript: function (data: string) {
    const script = document.createElement('script');
    script.innerHTML = data;
    return script;
  },
  createNoScript: function (data: string) {
    const noscript = document.createElement('noscript');
    noscript.innerHTML = data;
    return noscript;
  },
  createIframe: function (data: string) {
    return `<iframe src="${data}" height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;
  },
};
