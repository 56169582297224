import CircularProgress from '@material-ui/core/CircularProgress';
import IframeResizer from 'iframe-resizer-react';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { ApplicationWrapper } from '../../../wrappers/ApplicationWrapper';
import { InAppBrowserWrapper } from '../../../wrappers/InAppBrowserWrapper';
import { TARGET_MODES } from '../../../wrappers/InAppBrowserWrapper/others';

const e = React.createElement;

export interface ScanManagerProps {
  identityDocumentId: string;
  style: any;
  scanConfig: any;
  inNavigatorOptions: {
    text: string;
    toolbalcolor: string;
    closebuttonColor: string;
  };
  onSuccess: (eventData: {
    event: 'success' | 'failure' | 'cancel';
    value: any;
  }) => void;
  onFailure: (eventData: {
    event: 'success' | 'failure' | 'cancel';
    value: any;
  }) => void;
  onCancel: (eventData: {
    event: 'success' | 'failure' | 'cancel';
    value: any;
  }) => void;
}

//Este componente gestiona la creación del scan
export const ScanManagerBuilder = (Wrapper: any) =>
  forwardRef(
    (
      {
        identityDocumentId,
        style,
        scanConfig,
        onSuccess,
        onFailure,
        onCancel,
        inNavigatorOptions,
      }: ScanManagerProps,
      ref
    ) => {
      const { isIos } = ApplicationWrapper.use();
      const inappcontext = InAppBrowserWrapper.use();
      let open: any;
      if (inappcontext) {
        open = inappcontext?.open;
      }
      const { createScan, getBackendScanUrl } = Wrapper.use();

      const [currentScan, setCurrentScan] = useState<{ id: string }>();
      const [iframeLoading, setIsIframeLoading] = useState(true);

      const currentDniDocumentId = identityDocumentId;
      const currentScanId = currentScan?.id;
      const aliceLoaded =
        currentScanId && currentDniDocumentId && !iframeLoading;

      const initScan = async () => {
        setIsIframeLoading(true);
        setCurrentScan(undefined);
        let scan = await createScan(identityDocumentId, scanConfig);
        setCurrentScan(scan);
      };

      useImperativeHandle(ref, () => ({
        reset() {
          initScan();
        },
      }));

      // Segun se carga el componente crea el scan
      useEffect(() => {
        if (identityDocumentId) {
          initScan();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [identityDocumentId]);

      // Cuando este creado el Documento y el Escaneo se procede a escuchar el resultado
      useEffect(() => {
        if (currentScanId && currentDniDocumentId) {
          const listener = (event: any) => {
            const { data } = event;
            if (data.event === 'success') {
              onSuccess && onSuccess(data);
            } else if (data.event === 'failure') {
              onFailure && onFailure(data);
            } else if (data.event === 'cancel') {
              onCancel && onCancel(data);
            }
          };
          window.addEventListener('message', listener);
          return () => {
            window.removeEventListener('message', listener);
          };
        } else {
          return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [currentScanId, currentDniDocumentId]);

      const backendScanData = useMemo(() => currentScan?.id ? getBackendScanUrl(identityDocumentId, currentScan?.id) : undefined, [currentScan]);

      const [iframeContent, setIframeContent] = useState<string | undefined>(undefined);

      useEffect(() => {
        const getIframeContent = async () => {
          try {
            const respuesta = await fetch(backendScanData.url, {
              method: 'GET',
              headers: {
                'Authorization': 'Bearer ' + backendScanData?.userToken
              }
            });
            const contenido = await respuesta.text();
            setIframeContent(contenido);
          } catch (error) {
            onFailure({ event: 'failure', value: error })
            console.error('Error al obtener contenido:', error);
          }
        };

        if (backendScanData) {
          // console.log('## backendScanData', backendScanData)
          getIframeContent();
        }
      }, [backendScanData]);

      useEffect(() => {
        if (
          inNavigatorOptions &&
          identityDocumentId &&
          currentScan?.id &&
          open
        ) {
          //`closebuttoncaption=Volver a Pixel,footer=no,hideurlbar=yes,toolbarcolor=#ffffff,closebuttoncolor=#18b7cf,toolbarposition=top,${os === 'ios' ? 'lefttoright=yes' : ''}`
          open(
            backendScanData.url + '?userToken=' + backendScanData?.userToken,
            TARGET_MODES.BLANK,
            {
              closebuttoncaption: inNavigatorOptions.text,
              footer: false,
              hideurlbar: true,
              toolbarcolor: inNavigatorOptions.toolbalcolor ?? '#ffffff',
              closebuttoncolor:
                inNavigatorOptions.closebuttonColor ?? '#18b7cf',
              toolbarposition: 'top',
              ...(isIos ? { lefttoright: true } : {}),
            }
          );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [inNavigatorOptions?.text, currentScan?.id, identityDocumentId]);

      return e(
        React.Fragment,
        {},
        !aliceLoaded &&
        e(
          CircularProgress,
          {
            style: {
              marginTop: 10,
              marginBottom: 10,
              width: 40,
              height: 40,
            },
          },
          null
        ),
        identityDocumentId &&
        currentScan?.id &&
        (inNavigatorOptions && open
          ? e(
            CircularProgress,
            {
              style: {
                marginTop: 10,
                marginBottom: 10,
                width: 40,
                height: 40,
              },
            },
            null
          )
          : scanConfig?.metadata?.useIframeResizer && iframeContent
            ? e(
              IframeResizer,
              {
                style: {
                  width: '1px',
                  minWidth: '100%',
                  minHeight: '315px',
                  ...style,
                },
                frameBorder: '0',
                title: 'all-in-one-backend-scan',
                allow: 'camera *;microphone *;fullscreen *',
                // allowusermedia: 'true',
                onLoad: () => setIsIframeLoading(false),
                log: true,
                heightCalculationMethod: 'lowestElement',
                srcDoc: iframeContent,
              },
              null
            )
            : iframeContent ? e(
              'iframe',
              {
                style: {
                  ...style,
                },
                id: 'scanIframe',
                frameBorder: '0',
                title: 'all-in-one-backend-scan',
                allow: 'camera *;microphone *;fullscreen *',
                allowusermedia: 'true',
                onLoad: () => setIsIframeLoading(false),
                srcDoc: iframeContent,
              },
              null
            ) : e(
              CircularProgress,
              {
                style: {
                  marginTop: 10,
                  marginBottom: 10,
                  width: 40,
                  height: 40,
                },
              },
              null
            ))
      );
    }
  );
