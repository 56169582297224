import { init as init1 } from './branch-cordova-sdk/src/index';
import { init as init2 } from './cordova-clipboard/www/clipboard';
import { init as init3 } from './cordova-plugin-appversion/www/app-version';
import { init as init4 } from './cordova-plugin-device/www/device';
import { init as init5 } from './cordova-plugin-ionic-keyboard/www/ios/keyboard';
import { init as init6 } from './cordova-plugin-ionic-webview/src/www/util';
import { init as init7 } from './@havesource/cordova-plugin-push/www/push';
import { init as init8 } from './cordova-plugin-ionic-webview/src/www/ios/ios-wkwebview-exec';
import { init as init9 } from './blinkid-cordova/www/blinkIdScanner';
import { init as init10 } from './cordova-plugin-fingerprint-aio/www/Fingerprint';
import { init as init11 } from './cordova-plugin-network-information/www/network';
import { init as init12 } from './cordova-plugin-network-information/www/Connection';
import { init as init13 } from './cordova-plugin-networkinterface/www/networkinterface';
import { init as adjust } from './cordova-plugin-iroot/www/iroot';
import { init as init14 } from './cordova-plugin-advanced-http/www/advanced-http';
import { init as init15 } from './cordova-plugin-advanced-http/www/cookie-handler';
import { init as init16 } from './cordova-plugin-advanced-http/www/dependency-validator';
import { init as init17 } from './cordova-plugin-advanced-http/www/error-codes';
import { init as init18 } from './cordova-plugin-advanced-http/www/global-configs';
import { init as init19 } from './cordova-plugin-advanced-http/www/helpers';
import { init as init20 } from './cordova-plugin-advanced-http/www/js-util';
import { init as init21 } from './cordova-plugin-advanced-http/www/local-storage-store';
import { init as init22 } from './cordova-plugin-advanced-http/www/lodash';
import { init as init23 } from './cordova-plugin-advanced-http/www/messages';
import { init as init24 } from './cordova-plugin-advanced-http/www/ponyfills';
import { init as init25 } from './cordova-plugin-advanced-http/www/public-interface';
import { init as init26 } from './cordova-plugin-advanced-http/www/umd-tough-cookie';
import { init as init27 } from './cordova-plugin-advanced-http/www/url-util';
import { init as init28 } from './cordova-plugin-file/www/ios/FileSystem';
import { init as init29 } from './cordova-plugin-file/www/browser/isChrome';
import { init as init30 } from './cordova-plugin-file/www/DirectoryEntry';
import { init as init31 } from './cordova-plugin-file/www/DirectoryReader';
import { init as init32 } from './cordova-plugin-file/www/Entry';
import { init as init33 } from './cordova-plugin-file/www/File';
import { init as init34 } from './cordova-plugin-file/www/FileEntry';
import { init as init35 } from './cordova-plugin-file/www/FileError';
import { init as init36 } from './cordova-plugin-file/www/FileReader';
import { init as init37 } from './cordova-plugin-file/www/FileSystem';
import { init as init38 } from './cordova-plugin-file/www/FileUploadOptions';
import { init as init39 } from './cordova-plugin-file/www/FileUploadResult';
import { init as init40 } from './cordova-plugin-file/www/FileWriter';
import { init as init41 } from './cordova-plugin-file/www/Flags';
import { init as init42 } from './cordova-plugin-file/www/LocalFileSystem';
import { init as init43 } from './cordova-plugin-file/www/Metadata';
import { init as init44 } from './cordova-plugin-file/www/ProgressEvent';
import { init as init45 } from './cordova-plugin-file/www/fileSystemPaths';
import { init as init46 } from './cordova-plugin-file/www/fileSystems';
import { init as init47 } from './cordova-plugin-file/www/fileSystems-roots';
import { init as init48 } from './cordova-plugin-file/www/requestFileSystem';
import { init as init49 } from './cordova-plugin-file/www/resolveLocalFileSystemURI';
import { init as init50 } from './cordova-plugin-buildinfo/www/buildinfo';
import { init as init51 } from './cordova-plugin-inappbrowser/www/inappbrowser';
import { init as init52 } from './cordova-plugin-statusbar/www/statusbar';
import { init as init53 } from './cordova-plugin-cache-clear/www/CacheClear';
import { init as init54 } from './cordova-plugin-clear-data/www/ClearData';
import { init as init55 } from './cordova-plugin-camera/www/Camera';
import { init as init56 } from './cordova-plugin-camera/www/CameraConstants';
import { init as init57 } from './cordova-plugin-camera/www/ios/CameraPopoverHandle';
import { init as init58 } from './cordova-plugin-camera/www/CameraPopoverOptions';
import { init as init59 } from './cordova-plugin-camera-preview/www/CameraPreview';

export default {
  'plugins/branch-cordova-sdk/src/index.js': init1,
  'plugins/cordova-clipboard/www/clipboard.js': init2,
  'plugins/cordova-plugin-appversion/www/app-version.js': init3,
  'plugins/cordova-plugin-device/www/device.js': init4,
  'plugins/cordova-plugin-ionic-keyboard/www/ios/keyboard.js': init5,
  'plugins/cordova-plugin-ionic-webview/src/www/util.js': init6,
  'plugins/@havesource/cordova-plugin-push/www/push.js': init7,
  'plugins/cordova-plugin-ionic-webview/src/www/ios/ios-wkwebview-exec.js': init8,
  'plugins/blinkid-cordova/www/blinkIdScanner.js': init9,
  'plugins/cordova-plugin-fingerprint-aio/www/Fingerprint.js': init10,
  'plugins/cordova-plugin-network-information/www/network.js': init11,
  'plugins/cordova-plugin-network-information/www/Connection.js': init12,
  'plugins/cordova-plugin-networkinterface/www/networkinterface.js': init13,
  'plugins/cordova-plugin-iroot/www/iroot.js': adjust,
  'plugins/cordova-plugin-advanced-http/www/advanced-http.js': init14,
  'plugins/cordova-plugin-advanced-http/www/cookie-handler.js': init15,
  'plugins/cordova-plugin-advanced-http/www/dependency-validator.js': init16,
  'plugins/cordova-plugin-advanced-http/www/error-codes.js': init17,
  'plugins/cordova-plugin-advanced-http/www/global-configs.js': init18,
  'plugins/cordova-plugin-advanced-http/www/helpers.js': init19,
  'plugins/cordova-plugin-advanced-http/www/js-util.js': init20,
  'plugins/cordova-plugin-advanced-http/www/local-storage-store.js': init21,
  'plugins/cordova-plugin-advanced-http/www/lodash.js': init22,
  'plugins/cordova-plugin-advanced-http/www/messages.js': init23,
  'plugins/cordova-plugin-advanced-http/www/ponyfills.js': init24,
  'plugins/cordova-plugin-advanced-http/www/public-interface.js': init25,
  'plugins/cordova-plugin-advanced-http/www/umd-tough-cookie.js': init26,
  'plugins/cordova-plugin-advanced-http/www/url-util.js': init27,
  'plugins/cordova-plugin-file/www/ios/FileSystem.js': init28,
  'plugins/cordova-plugin-file/www/browser/isChrome.js': init29,
  'plugins/cordova-plugin-file/www/DirectoryEntry.js': init30,
  'plugins/cordova-plugin-file/www/DirectoryReader.js': init31,
  'plugins/cordova-plugin-file/www/Entry.js': init32,
  'plugins/cordova-plugin-file/www/File.js': init33,
  'plugins/cordova-plugin-file/www/FileEntry.js': init34,
  'plugins/cordova-plugin-file/www/FileError.js': init35,
  'plugins/cordova-plugin-file/www/FileReader.js': init36,
  'plugins/cordova-plugin-file/www/FileSystem.js': init37,
  'plugins/cordova-plugin-file/www/FileUploadOptions.js': init38,
  'plugins/cordova-plugin-file/www/FileUploadResult.js': init39,
  'plugins/cordova-plugin-file/www/FileWriter.js': init40,
  'plugins/cordova-plugin-file/www/Flags.js': init41,
  'plugins/cordova-plugin-file/www/LocalFileSystem.js': init42,
  'plugins/cordova-plugin-file/www/Metadata.js': init43,
  'plugins/cordova-plugin-file/www/ProgressEvent.js': init44,
  'plugins/cordova-plugin-file/www/fileSystemPaths.js': init45,
  'plugins/cordova-plugin-file/www/fileSystems.js': init46,
  'plugins/cordova-plugin-file/www/fileSystems-roots.js': init47,
  'plugins/cordova-plugin-file/www/requestFileSystem.js': init48,
  'plugins/cordova-plugin-file/www/resolveLocalFileSystemURI.js': init49,
  'plugins/cordova-plugin-buildinfo/www/buildinfo.js': init50,
  'plugins/cordova-plugin-inappbrowser/www/inappbrowser.js': init51,
  'plugins/cordova-plugin-statusbar/www/statusbar.js': init52,
  'plugins/cordova-plugin-cache-clear/www/CacheClear.js': init53,
  'plugins/cordova-plugin-clear-data/www/ClearData.js': init54,
  'plugins/cordova-plugin-camera/www/Camera.js': init55,
  'plugins/cordova-plugin-camera/www/CameraConstants.js': init56,
  'plugins/cordova-plugin-camera/www/ios/CameraPopoverHandle.js': init57,
  'plugins/cordova-plugin-camera/www/CameraPopoverOptions.js': init58,
  'plugins/cordova-plugin-camera-preview/www/CameraPreview.js': init59,
};
