/* eslint-disable no-undef */
export const init = () => {
  cordova.define('cordova-plugin-file.File', function(
    require,
    exports,
    module
  ) {
    /*
     *
     * Licensed to the Apache Software Foundation (ASF) under one
     * or more contributor license agreements.  See the NOTICE file
     * distributed with this work for additional information
     * regarding copyright ownership.  The ASF licenses this file
     * to you under the Apache License, Version 2.0 (the
     * "License"); you may not use this file except in compliance
     * with the License.  You may obtain a copy of the License at
     *
     *   http://www.apache.org/licenses/LICENSE-2.0
     *
     * Unless required by applicable law or agreed to in writing,
     * software distributed under the License is distributed on an
     * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
     * KIND, either express or implied.  See the License for the
     * specific language governing permissions and limitations
     * under the License.
     *
     */

    /**
     * Constructor.
     * name {DOMString} name of the file, without path information
     * fullPath {DOMString} the full path of the file, including the name
     * type {DOMString} mime type
     * lastModifiedDate {Date} last modified date
     * size {Number} size of the file in bytes
     */

    const File = function(name, localURL, type, lastModifiedDate, size) {
      this.name = name || '';
      this.localURL = localURL || null;
      this.type = type || null;
      this.lastModified = lastModifiedDate || null;
      // For backwards compatibility, store the timestamp in lastModifiedDate as well
      this.lastModifiedDate = lastModifiedDate || null;
      this.size = size || 0;

      // These store the absolute start and end for slicing the file.
      this.start = 0;
      this.end = this.size;
    };

    /**
     * Returns a "slice" of the file. Since Cordova Files don't contain the actual
     * content, this really returns a File with adjusted start and end.
     * Slices of slices are supported.
     * start {Number} The index at which to start the slice (inclusive).
     * end {Number} The index at which to end the slice (exclusive).
     */
    File.prototype.slice = function(start, end) {
      const size = this.end - this.start;
      let newStart = 0;
      let newEnd = size;
      if (arguments.length) {
        if (start < 0) {
          newStart = Math.max(size + start, 0);
        } else {
          newStart = Math.min(size, start);
        }
      }

      if (arguments.length >= 2) {
        if (end < 0) {
          newEnd = Math.max(size + end, 0);
        } else {
          newEnd = Math.min(end, size);
        }
      }

      const newFile = new File(
        this.name,
        this.localURL,
        this.type,
        this.lastModified,
        this.size
      );
      newFile.start = this.start + newStart;
      newFile.end = this.start + newEnd;
      return newFile;
    };

    module.exports = File;
  });
};
