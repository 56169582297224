import moment from 'moment';

import { Logger } from '../../../generic/utils';
import { wrapperIdentifier } from '../common';
import { TRANSPORTS } from './transportDefinitions';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare var DFI: any;

var inited = false;

export const WalmericDelio = {
  initialize: async function(
    getInstanceApi: any,
    user: any,
    loadSessionUser: any,
    {
      debugEnabled,
    }: {
      debugEnabled?: boolean;
    }
  ) {
    if (!user) {
      inited = false;
      return;
    }
    if (!inited) {
      debugEnabled && console.log(`## 📣 ${TRANSPORTS.WALMERIC_DELIO} INITING`);
      inited = true;

      let analyticId = user?.metadata?.analytics?.WALMERIC_DELIO?.id;

      // SI NO TIENE LA ANALYTIC CREADA SE CREA
      if (!analyticId) {
        try {
          analyticId = await this.startAnalytic({
            getInstanceApi,
            user,
            debugEnabled,
          });
          if (analyticId) {
            await loadSessionUser();
          }
        } catch (error) {
          throw error;
        }
      }
      console.log(`## ✅ ${TRANSPORTS.WALMERIC_DELIO} analyticId`, analyticId);

      // SE AÑADEN LOS SCRIPTS
      try {
        const addedScripts = await this.addScripts({
          getInstanceApi,
          debugEnabled,
          analyticId,
        });
        debugEnabled &&
          console.log(
            `## ✅ ${TRANSPORTS.WALMERIC_DELIO} addedScripts`,
            addedScripts
          );
      } catch (error) {
        throw error;
      }
    }
  },
  startAnalytic: async function(args: { [k: string]: any }) {
    const { getInstanceApi, user, debugEnabled } = args;

    debugEnabled &&
      console.log(`## 📣 CREATE NEW ${TRANSPORTS.WALMERIC_DELIO} ANALYTICS`);

    try {
      const apiInstance = getInstanceApi();

      const transports = await apiInstance.microserviceCall('Analytics')(
        '/transports',
        'GET',
        {
          filter: { where: { identifier: TRANSPORTS.WALMERIC_DELIO } },
        }
      );

      if (transports.length === 0) {
        throw new Error('WALMERIC_DELIO is not actived');
      }

      const analyticsCreated = await apiInstance.microserviceCall('Analytics')(
        `/users/${user.id}/anltcs`,
        'POST',
        {
          transportId: transports[0].id,
          input: {},
        }
      );

      debugEnabled &&
        console.log(
          ` ${TRANSPORTS.WALMERIC_DELIO} analyticsCreated`,
          analyticsCreated
        );

      let analyticPooled: any = {};
      let status = analyticsCreated.status;
      let finishDate = moment()
        .add(20, 'seconds')
        .toDate();
      while (
        (status !== 'CREATED_SUCCESS' && status !== 'CREATED_ERROR') ||
        +new Date() > +finishDate
      ) {
        await new Promise(r => setTimeout(r, 1000));
        analyticPooled = await apiInstance.microserviceCall('Analytics')(
          `/anltcs/${analyticsCreated.id}`,
          'GET'
        );
        status = analyticPooled.status;
      }

      if (status === 'CREATED_ERROR') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.WALMERIC_DELIO} startAnalytic analyticPooled`,
            analyticPooled
          );
        throw new Error('Error creating analytic');
      } else if (status !== 'CREATED_SUCCESS') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.WALMERIC_DELIO} startAnalytic analyticPooled`,
            analyticPooled
          );
        throw new Error('Timeout reached creating analytic');
      }
      // debugEnabled &&
      //   console.log(
      //     `## ✅ ${TRANSPORTS.WALMERIC_DELIO} startAnalytic analyticPooled`,
      //     analyticPooled
      //   );

      return analyticsCreated.id;
    } catch (error) {
      debugEnabled && console.log('## 📌 error', error);
      throw error;
    }
  },
  addScripts: async function(args: { [k: string]: any }) {
    const { debugEnabled } = args;

    try {
      const scripts = await this.getScripts(args);
      debugEnabled &&
        console.log(`## 🧩 ${TRANSPORTS.WALMERIC_DELIO} scripts`, scripts);

      if (scripts.jQueryScript) {
        const script = this.srcScript(scripts.jQueryScript);
        document.body.prepend(script);
      }
      if (scripts.dataLayerScript) {
        const script = this.dataScript(scripts.dataLayerScript);
        document.body.prepend(script);
      }
      if (scripts.mainScript) {
        const script = this.srcScript(scripts.mainScript);
        document.body.prepend(script);
      }
      if (scripts.leadScript) {
        const script = this.dataScript(scripts.leadScript);
        document.body.prepend(script);
      }
      if (scripts.formScript) {
        const script = this.dataScript(scripts.formScript);
        document.body.prepend(script);
      }
      if (!scripts.mainScript && !scripts.leadScript && !scripts.formScript) {
        return false;
      }

      return true;
    } catch (error) {
      debugEnabled && console.log('## ❌ error', error);
      throw error;
    }
  },
  getScripts: async function(args: { [k: string]: any }) {
    const { getInstanceApi, debugEnabled, analyticId } = args;
    debugEnabled &&
      console.log(`## 🧩 ${TRANSPORTS.WALMERIC_DELIO} GET SCRIPTS`);

    try {
      const apiInstance = getInstanceApi();

      const eventDefinitions = await apiInstance.microserviceCall('Analytics')(
        '/event-definitions',
        'GET',
        {
          filter: {
            where: {
              identifier: `${TRANSPORTS.WALMERIC_DELIO}_GENERATE_SCRIPTS`,
              transportIdentifier: TRANSPORTS.WALMERIC_DELIO,
            },
          },
        }
      );

      if (eventDefinitions.length === 0) {
        throw new Error('GENERATE_SCRIPTS for WALMERIC_DELIO is not actived');
      }

      const eventCreated = await apiInstance.microserviceCall('Analytics')(
        `/anltcs/${analyticId}/events`,
        'POST',
        {
          eventDefinitionId: eventDefinitions[0].id,
          input: {},
        }
      );
      debugEnabled &&
        console.log(`${TRANSPORTS.WALMERIC_DELIO} eventCreated`, eventCreated);

      let eventPooled: any = {};
      let status = eventCreated.status;
      let finishDate = moment()
        .add(20, 'seconds')
        .toDate();
      while (
        (status !== 'FINISHED_SUCCESS' && status !== 'FINISHED_ERROR') ||
        +new Date() > +finishDate
      ) {
        await new Promise(r => setTimeout(r, 1000));
        eventPooled = await apiInstance.microserviceCall('Analytics')(
          `/events/${eventCreated.id}`,
          'GET'
        );
        status = eventPooled.status;
      }

      if (status === 'FINISHED_ERROR') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.WALMERIC_DELIO} getScripts eventPooled`,
            eventPooled
          );
        throw new Error('Error creating event');
      } else if (status !== 'FINISHED_SUCCESS') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.WALMERIC_DELIO} getScripts eventPooled`,
            eventPooled
          );
        throw new Error('Timeout reached creating event');
      }
      // debugEnabled &&
      //   console.log(
      //     `## ✅ ${TRANSPORTS.WALMERIC_DELIO} getScripts eventPooled`,
      //     eventPooled
      //   );

      return { ...eventPooled.output };
    } catch (error) {
      debugEnabled && console.log('## ❌ error', error);
      throw error;
    }
  },
  srcScript: function(src: string) {
    const script = document.createElement('script');
    script.src = src;
    return script;
  },
  dataScript: function(data: string) {
    const script = document.createElement('script');
    script.innerHTML = data;
    return script;
  },
  leadRegister: async function(
    generateLeadId: boolean,
    debugEnabled: any,
    user: any,
    apiInstance: any,
    leadData: any
  ): Promise<void> {
    const registerLead = async () => {
      debugEnabled &&
        console.log(
          `## 📝 ${TRANSPORTS.WALMERIC_DELIO} leadRegister`,
          leadData
        );

      if (!user?.metadata?.analytics?.[TRANSPORTS.WALMERIC_DELIO]?.id) {
        let analyticInited: boolean = false;
        let id: string | undefined = undefined;
        let finishDate = moment()
          .add(10, 'seconds')
          .toDate();

        while (id === undefined || +new Date() > +finishDate) {
          await new Promise(r => setTimeout(r, 1000));
          analyticInited = user?.metadata?.analytics?.[
            TRANSPORTS.WALMERIC_DELIO
          ]?.id
            ? true
            : false;
          id =
            user?.metadata?.analytics?.[TRANSPORTS.WALMERIC_DELIO]?.id ??
            undefined;
        }

        if (!analyticInited || id === undefined) {
          debugEnabled &&
            console.log(`## ❌ ${TRANSPORTS.WALMERIC_DELIO} not inited`);
          throw new Error(`${TRANSPORTS.WALMERIC_DELIO} not inited`);
        }
      }

      try {
        let idLead = undefined;
        if (generateLeadId) {
          try {
            idLead = DFI.getLeadId();
            if (debugEnabled) {
              console.log('## 📌 idLead creado con script', idLead);
            }
          } catch (error) {
            console.log(
              '❌ error. Puede provocar el error que el usuario tenga un blockeador de anuncios en el navegador. No pasa nada ya que si no se genera en este punto lo genera Delio al recibir la petición.',
              error
            );
          }
        }

        // Mock
        // return { code: 200, leadId: idLead };

        const eventDefinitions = await apiInstance.microserviceCall(
          'Analytics'
        )('/event-definitions', 'GET', {
          filter: {
            where: { identifier: `${TRANSPORTS.WALMERIC_DELIO}_LEAD_REGISTER` },
          },
        });

        const eventCreated = await apiInstance.microserviceCall('Analytics')(
          `/anltcs/${
            user.metadata.analytics[TRANSPORTS.WALMERIC_DELIO].id
          }/events`,
          'POST',
          {
            eventDefinitionId: eventDefinitions[0].id,
            input: {
              phone: leadData?.phone,
              email: leadData?.email,
              name: leadData?.name,
              analyticId: user.metadata.analytics[TRANSPORTS.WALMERIC_DELIO].id,
              generatedLeadId: idLead,
              declarativeLeadData: {
                solicita_desde: leadData?.solicita_desde,
                horario_llamadas: leadData?.horario_llamadas || undefined,
                dynamicDeclarative: leadData?.dynamicDeclarative || undefined,
              },
              conversionLeadData: {
                solicita_desde: leadData?.solicita_desde,
              },
            },
          }
        );
        if (debugEnabled) {
          console.log('eventCreated', eventCreated);
        }

        let eventPooled: any = {};
        let status = eventCreated.status;
        let finishDate = moment()
          .add(20, 'seconds')
          .toDate();
        while (
          (status !== 'FINISHED_SUCCESS' && status !== 'FINISHED_ERROR') ||
          +new Date() > +finishDate
        ) {
          await new Promise(r => setTimeout(r, 1000));
          eventPooled = await apiInstance.microserviceCall('Analytics')(
            `/events/${eventCreated.id}`,
            'GET'
          );
          if (debugEnabled) {
            console.log('eventPooled', eventPooled);
          }
          status = eventPooled.status;
        }

        if (status === 'FINISHED_ERROR') {
          debugEnabled &&
            console.log(
              `## ❌ ${TRANSPORTS.WALMERIC_DELIO} leadRegister eventPooled`,
              eventPooled
            );
          throw new Error('Error creating event');
        } else if (status !== 'FINISHED_SUCCESS') {
          debugEnabled &&
            console.log(
              `## ❌ ${TRANSPORTS.WALMERIC_DELIO} leadRegister eventPooled`,
              eventPooled
            );
          throw new Error('Timeout reached creating event');
        }
        debugEnabled &&
          console.log(
            `## ✅ ${TRANSPORTS.WALMERIC_DELIO} leadRegister eventPooled`,
            eventPooled
          );
        return eventPooled;
      } catch (e) {
        const error = e as Error;
        console.log('## ❌ error', error);
        throw new Error(`Error creating new lead - ${error?.message}`);
      }
    };

    const addConversionOnlineScript = async (script: string) => {
      const walmericDelioConversionOnlineScript = this.srcScript(script);
      document.body.appendChild(walmericDelioConversionOnlineScript);
      return;
    };

    return await new Promise<void>((res, rej) => {
      (async () => {
        try {
          const response: any = await registerLead();
          if (response.status === 'FINISHED_SUCCESS') {
            if (response.output.code === 200) {
              if (response.output.addConversionOnlineScript) {
                await addConversionOnlineScript(
                  response.output.addConversionOnlineScript
                );
              }
              res(response.output.leadId);
            } else if (response.output.code === 485) {
              if (debugEnabled) {
                console.log(
                  '## 💥 WARN Lead en proceso de registro',
                  response.output
                );
              }
              res(response.output.message);
            } else {
              if (debugEnabled) {
                console.log('## ❌ ERROR', response.output);
              }
              rej(String(response.output.message));
            }
          } else if (response.status === 'FINISHED_ERROR') {
            rej(String(response.error.message));
          } else if (response.status === 'INVOKED') {
            rej(String('Error creating new lead - Event invoked'));
          } else {
            rej(String('Error creating new lead - Not valid event status'));
          }
        } catch (e) {
          const error = e as Error;
          if (debugEnabled) {
            console.log('## ❌ ERROR', error);
          }
          rej(String(error?.message));
        }
      })();
    });
  },
};
